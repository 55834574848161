<template>
  <div class="Page">
    <split-pane split="vertical" class="pane" :default-percent='30' :resizerThickness="5" @resize="paneResizeVertical">
      <template slot="paneL">
        <div class="left-pan">
          <div class="pan-bar">
            <icon-text-btn icon="iconnew" size="small" label="新角色"></icon-text-btn>
            <icon-btn icon="iconrefresh" style="float:right" @click="queryRoles" border size="small" tip="刷新"></icon-btn>
          </div>
          <ul class="roles">
            <template v-for="(item, idx) in roleList">
              <li @click="handleRoleSelect(item)" :key="idx"
                :class="'role-curren_' + (currentRole === item.id)">
                <div class="role-id">
                  <i class="iconfont iconuser"></i>
                  {{item.id}}
                </div>
                <div class="role-prop">
                  <div class="opt">
                    <icon-btn icon="iconcopy" size="mini"></icon-btn>
                    <icon-btn icon="iconedit1" size="mini"></icon-btn>
                    <icon-btn icon="iconclose" size="mini"></icon-btn>
                  </div>
                  <div class="count">
                    用户：{{item.user_cc}}，模块：{{item.module_cc}}
                  </div>
                </div>
              </li>
            </template>
          </ul>
        </div>
      </template>
      <template slot="paneR">
        <div class="pan-bar">
          <el-checkbox class="checkAll" v-model="checkAll" @change="handleCheckAll">全选</el-checkbox>
          <icon-text-btn icon="iconclose" @click="deletePermissions" label="删除" tip="删除所选择的节点"></icon-text-btn>
          <icon-text-btn icon="iconadd" @click="addPermissions" label="添加权限" tip="为该角色添加权限"></icon-text-btn>
          <icon-btn icon="iconrefresh" style="float:right" @click="refreshPermission" border size="small" tip="刷新权限"></icon-btn>
        </div>
        <div class="permisions">
          <el-tree
          ref="vuetree"
          :data="permissions"
          :props="props"
          node-key="mid"
          show-checkbox
          @node-expand="onNodeExpend"
          @node-collapse="onNodeCollapse"
          :default-expanded-keys="expandNodes"
          @check-change="handleCheckChange"
          >
           <span class="custom-tree-node" slot-scope="{ node, data }">
              <span class="node-label"><i :class="'iconfont ' + data.icon"></i> {{ data.name }}</span>
              <span class="node-checks" v-if="data.upid>0">
                <el-checkbox :true-label="1" :false-label="0" v-model="data.al" @change="onItemCheckedAll(data)">全选</el-checkbox>
                <el-checkbox v-model="data.view" @change="onItemChecked(data)">read</el-checkbox>
                <el-checkbox v-model="data.edit" @change="onItemChecked(data)">edit</el-checkbox>
                <el-checkbox v-model="data.del" @change="onItemChecked(data)">delete</el-checkbox>
              </span>
              <span class="node-opts">
                <icon-text-btn icon="iconclose" label="移除" size="mini" tip="移除这项权限"></icon-text-btn>
              </span>
            </span>
          </el-tree>
        </div>
      </template>
    </split-pane>

    <el-dialog
      width="57em"
      title="选择需要添加的权限"
      :visible.sync="dialogVisible"
      :before-close="handleDialogClose"
      >
        <el-transfer
          class="transfer"
          filterable
          :filter-method="filterMethod"
          filter-placeholder="查找"
          :titles="['备选', '已选']"
          :props="transferProps"
          v-model="toAddPerms"
          :data="allPermissions">
          <span slot-scope="{ option }">
            <i :class="'iconfont '+option.upicon"></i> {{ option.upname }}
            /
            <i :class="'iconfont '+option.icon"></i> {{ option.name }}
          </span>
        </el-transfer>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onAddPermissionCancel">取 消</el-button>
        <el-button type="primary" @click="onAddPermissionSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <div v-if="refresh"></div>
  </div>
</template>

<script>
import splitPane from 'vue-splitpane'
import IconBtn from '../../components/btns/IconBtn';
import IconTextBtn from '../../components/btns/IconTextBtn.vue'
export default {
  components: { splitPane, IconBtn, IconTextBtn, },
  data() {
    return {
      refresh: false,
      checkAll: false,
      props: {id: 'mid', label: 'name', children: 'children'},
      role: '',
      currentRole: 'ADMIN',
      roleList: [],
      permissions: [],
      expandNodes: [],

      dialogVisible: false,
      allPermissions: [],
      toAddPerms: [],
      transferProps: {key: 'mid', label: 'name'},
    };
  },
  created(opt) {

    // window.onresize = this.resize();
    this.queryRoles()
    // this.onCurrentPageChange()
  },
  mounted() {},
  methods: {
    deletePermissions() {
      var checked = this.$refs.vuetree.getCheckedNodes()
      var list = []
      for(var n=0; n<checked.length; n++) {
        var element = checked[n]
        var ob = this.util.copyFrom(element, "id, module,upid, role, view, edit, del, atime")
        if (ob.upid>0) {
          list.push(ob)
        } else {
          var top = this.util.find(this.permissions, ob)
          if (top && element.children.length == top.children.length)  {
            list.push(ob)
          }
        }
      };
      // console.log(list)
      var pm = {
        role: this.currentRole,
        list: list
      }
      this.post('api/sys/permissionRoleDelele', pm, res=>{
        if (res.code != 0) {
          this.$message.error('操作失败~' + res.msg)
          return
        }
        this.$message.success('操作成功~')
        this.refreshPermission()
      })
    },
    onNodeExpend(row) {
      this.expandNodes.push(row.mid)
      // console.log(this.expandNodes)
    },
    onNodeCollapse(row) {
      this.expandNodes.remove(row.mid)
      // console.log(this.expandNodes)
    },
    onItemCheckedAll(row) {
      const al = row.al
      row.view = al
      row.edit = al
      row.del = al
    },
    onItemChecked(row) {//修改权限
      if (row.edit || row.del) { //有修改与删除就必须有可读
        row.view = 1
      }
      if (row.edit && row.del && row.view) {
        row.al = 1
      }
      var ob = this.util.copyFrom(row, "id, module, role, view, edit, del, atime")
      var pm = {
        role: this.currentRole,
        list: [ob]
      }
      row.role = this.currentRole
      this.post('api/sys/permissionRoleEdit', pm, res=>{
        if (res.code != 0) {
          this.$message.error('操作失败~' + res.msg)
          return
        }
        this.$message.success('操作成功~')
        this.queryPermissions(this.currentRole)
      })
    },
    handleRoleSelect(item) { //当前角色变化
      this.currentRole = item.id
      this.queryPermissions(item.id)
    },
    refreshPermission() {
      this.queryPermissions(this.currentRole)
    },
    queryPermissions(role) {
      this.post('api/sys/permissionForRole', {role: role}, res=>{
        if (res.code != 0) {
          this.$message.error('获取' + role + '的权限失败~' + res.msg)
          return
        }
        this.permissions = this.util.makeTree(res.data, 'mid');
      })
    },
    queryRoles() {
      this.post('api/sys/roles', {}, res=>{
        if (res.code != 0) {
          this.$message.error('获取角色信息失败：' + res.msg)
          return
        }
        this.roleList = res.data
        if (this.roleList.length > 0) {
          var row = this.roleList[0]
          this.handleRoleSelect(row)
        }
      })
    },
    paneResizeVertical(e) {

    },
    handleCheckAll(e) {
      if (e) {
            //全选
          this.$refs.vuetree.setCheckedNodes(this.permissions);
      }else{
          //取消选中
          this.$refs.vuetree.setCheckedKeys([]);
      }
    },
    handleCheckChange(e){

    },
    handleNewRole() {

    },

    handleDialogClose(done) { //权限对话框关闭
      if (this.toAddPerms.length > 0) {
        this.$confirm('数据还没保存，确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      } else {
        done()
      }
    },
    onAddPermissionCancel() {
      if (this.toAddPerms.length > 0) {
        this.$confirm('数据还没保存，确认关闭？')
          .then(_ => {
            this.dialogVisible = false
          })
          .catch(_ => {});
      } else {
        this.dialogVisible = false
      }
    },
    onAddPermissionSubmit() { //保存新增的权限
      this.loading.show();
      var pm = {
        role: this.currentRole,
        list: this.toAddPerms
      }
      this.post('api/sys/permissionAddToRole', pm, res=>{
        this.loading.hide()
        if (res.code != 0) {
          this.$message.error('操作失败~' + res.msg)
          return
        }
        this.$message.success('操作成功~')
        this.dialogVisible = false
        this.refreshPermission() //刷新
      })
    },
    addPermissions() {
      this.dialogVisible = true
      this.post('api/sys/permissionAll', {}, res=>{
        if (res.code != 0) {
          this.$message.error('出错了~' + res.msg);
          return
        }
        this.queryTime = res.pageTime
        var list = res.data
        //将该角色已经有的权限过滤掉
        for(var n=0; n<this.permissions.length; n++) {
          var val = this.permissions[n]
          for(var m=0; m<val.children.length; m++) {
            var sub = val.children[m]
            var nn = this.util.indexOf(list, sub.mid, 'mid')
            list.splice(nn, 1)
          }
        }
        this.toAddPerms = []
        this.allPermissions = list// this.util.makeTree(res.data, 'mid');
        // this.refresh = !this.refresh
      })
    }, //loadModules
    filterMethod(query, item) {
      return item.name.indexOf(query) > -1 || item.upname.indexOf(query) > -1;
    }
  }//methods
};
</script>

<style scoped lang="scss">
.Page {
  margin-top: 0px;
  border: 1px solid #cbcbcb;

  .permisions{
    margin: 20px 20px;
  }
  .transfer {
    width:100%;
    margin-top: 0px;
    text-align: left;
    display: inline-block;
    white-space:nowrap;
    // border: 1px solid red;

    .transfer-item{
      display: inline-block;
      text-align: left;
    }

    .transfer-footer{
      text-align: right;
      margin: 0px;
    }
  }
  .transfer >>> .el-transfer-panel {
      // width:calc((100vw - 500px)/2);
      width: 16.5em;
      height: calc(100vh * 0.4);
      border: 1px solid #cbcbcb;
      background-color: #fff;
  }

  .transfer >>> .el-transfer-panel__list.is-filterable{
    height: calc(100vh * 0.4 - 110px);
    // border: 1px solid red;
  }
}

.pan-bar {
  background: #efefef;
  padding: 3px 10px;
  height: 32px;
  line-height: 32px;
  text-align: left;

  .checkAll{
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 5px;
  }
  .checkAll:hover {
    background-color: $dark;
    color: #fff;
  }
}

.roles{
  width: 99%;
  // margin: 2px;
  margin: auto;
  height: calc(100vh - 120px);
  overflow-y: auth;
  // border: 1px solid red;

  li {
    text-align: left;
    padding: 3px 10px;
    margin-top: 1px;
    border: 1px solid #efefef;
    height: 60px;
    display: flex;
    cursor: pointer;
    margin-left: 0;

    .role-id {
      font-weight: 700;
      font-size: 1.2em;
      margin-top: 15px;
    }

    .role-prop{
      flex: 1 1 auto;
      margin-left: 10px;
      text-align: right;

      .count{
        margin-top: 15px;
        color: $gray;
      }
    }
  }
  .role-curren_true {
    background-color: $hot;
  }
  li:hover{
    background-color: $bg;
  }
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;

  .node-label {
    // font-weight: 700;
    // color: $primary;
  }

  .node-checks {
    flex: 1 1 auto;
    text-align: right;
  }

  .node-opts{
    float: right;
    width: 5em;
  }
}

.dlg-tree{
  // height: calc(100vh * 0.3);
  overflow-y: auto;

  .dlg-search {
    position: absolute;
    margin-left: 40%;
    margin-top: -75px;
    width: 40%;
    z-index: +1;
  }
}

</style>
